import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { SentryService, UserFolderService } from "services"
import { Box, Row } from "components/atoms/_atoms"
import LoaderBlock from "components/shared/blocks/LoaderBlock"
import { ShortDocumentSignatureType } from "services/types"
import SignatureRowBlock from "components/shared/Documents/Signature/blocks/SignatureRowBlock"
import { P, Title } from "components/atoms/typography"
import Button from "components/atoms/button/Button"
import { IconButton } from "components/atoms/button"
import Alert from "components/molecules/alert"
import Input from "components/atoms/input"
import Modal from "components/molecules/modal"
import { deleteFolder, editFolder, useFolder } from "store/folders"

const EditFolder = ({
  id,
  name,
  setShow,
}: {
  id: string
  name: string
  setShow: (e: boolean) => void
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: name,
    },
  })

  const dispatch = useDispatch()

  const [response, setResponse] = useState<boolean | string>(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    try {
      setLoading(true)
      const folder = await UserFolderService.editFolder(id, el.name)
      dispatch(editFolder(folder))
      setLoading(false)
      setResponse(false)
      setShow(false)
    } catch (e) {
      setLoading(false)
      setResponse("error")
      SentryService.error("[ERROR UserFolderService.editFolder]:", e)
      setTimeout(() => {
        setResponse(false)
      }, 3000)
    }
  }, [])

  return (
    <>
      {response === "error" && (
        <Alert type="error" small marginBottom="S">
          Nie udało się edytować nazwy folderu.
        </Alert>
      )}
      {response === "success" && (
        <Alert type="success" small marginBottom="S">
          Pomyślnie edytowano nazwę folderu.
        </Alert>
      )}
      <Input
        name="name"
        label={"Nazwa folderu*"}
        error={errors.name}
        rhf={register("name", {
          required: true,
        })}
        marginBottom="S"
      />
      <Button loading={loading} width="100%" icon="save" onClick={handleSubmit(onSubmit)}>
        Zapisz
      </Button>
    </>
  )
}

const FolderFavouritesView = () => {
  const { folderId = "" } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const folder = useFolder(folderId)

  const [editFolder, setEditFolder] = useState(false)

  const [loading, setLoading] = useState(true)
  const [deleting, setDeleting] = useState(false)
  const [data, setData] = useState<ShortDocumentSignatureType[]>([])

  const fetchData = async () => {
    //TODO: Change to use-query
    try {
      setLoading(true)
      const content = await UserFolderService.getFolderDocuments(folderId)
      setData(content)
    } catch (e) {
      SentryService.error("[ERROR UserFolderService.getFolderDocuments[:", e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleDeleteFolder = async () => {
    if (confirm(`Czy na pewno chcesz usunąć folder ${folder?.name}?`) == true) {
      try {
        setDeleting(true)
        await UserFolderService.deleteFolder(folderId)
        navigate(-1)
        setTimeout(() => {
          dispatch(deleteFolder(folderId))
          setDeleting(false)
        }, 500)
      } catch (e) {
        setDeleting(false)
        SentryService.error("[ERROR UserFolderService.deleteFolder]:", e)
      }
    }
  }

  const isAll = folderId === "all"

  return (
    <>
      <Wrapper>
        <Row justifyContent="space-between" alignItems="center">
          <Row alignItems="center">
            <IconButton
              borderRadius="borderRadius"
              size="45px"
              icon="arrow-left"
              marginRight="S"
              onClick={() => navigate(-1)}
            />
            <Title margin="0" color={isAll ? "primary" : "secondary"}>
              {isAll ? "Wszystkie dokumenty" : folder.name}
            </Title>
          </Row>
          {!isAll && (
            <ButtonsWrapper>
              <Button onClick={() => setEditFolder(true)} type="secondary" icon="edit">
                Edytuj nazwę
              </Button>
              {!folder.isDefault && (
                <IconButton
                  color="red"
                  borderRadius="borderRadius"
                  size="45px"
                  icon="trash"
                  loading={deleting}
                  onClick={handleDeleteFolder}
                />
              )}
            </ButtonsWrapper>
          )}
        </Row>
        {loading && (
          <>
            <LoaderBlock height="80px" />
            <LoaderBlock height="80px" />
            <LoaderBlock height="80px" />
            <LoaderBlock height="80px" />
          </>
        )}
        {!loading &&
          folder.documentIds.length > 0 &&
          data.length > 0 &&
          data.filter(el => folder.documentIds.includes(el.id)) &&
          data.map((el, index) => {
            if (folder.documentIds.includes(el.id)) {
              return <SignatureRowBlock key={index} folderId={folderId} data={el} />
            }
          })}
        {!loading && (data.length === 0 || folder.documentIds.length === 0) && (
          <Row marginTop="M" justifyContent="center">
            <P small>Brak ulubionych dokumentów</P>
          </Row>
        )}
      </Wrapper>
      <Modal
        icon="edit"
        show={editFolder}
        setShow={setEditFolder}
        title="Edytuj folder"
        text="Edytuj folder ulubionych dokumentów."
      >
        <EditFolder id={folderId} name={folder.name} setShow={setEditFolder} />
      </Modal>
      <Outlet />
    </>
  )
}

export default FolderFavouritesView

const ButtonsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.S};
`
