import React, { useState } from "react"
import styled from "styled-components"
import SignatureContentHeader from "components/shared/Documents/Signature/components/SignatureContentHeader"
import SignatureContentInnerContent from "components/shared/Documents/Signature/components/SignatureContentInnerContent"
import SignatureContentInfo from "components/shared/Documents/Signature/components/SignatureContentInfo"
import { Box } from "components/atoms/_atoms"
import { DocumentSignatureType } from "services/types"
import DocumentChat from "components/shared/Documents/components/DocumentChat"
import SignatureContentFooter from "components/shared/Documents/Signature/components/SignatureContentFooter"

const Signature = ({ data, q }: { data: DocumentSignatureType; q: string }) => {
  const [showChat, setShowChat] = useState(false)

  return (
    <Content showChat={showChat}>
      <SignatureContentInnerContent data={data} />
      <SignatureContentInfo q={q} data={data} showChat={showChat} setShowChat={setShowChat} />
      {showChat && <DocumentChat id={data?.id} show={showChat} setShow={setShowChat} />}
    </Content>
  )
}

export default Signature

const Content = styled(Box)<{
  showChat: boolean
}>`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: ${({ showChat }) => (showChat ? "2fr 1fr 400px" : "2fr 1fr")};
`
