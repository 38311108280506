import React from "react"
import styled from "styled-components"
import { Box, Row } from "components/atoms/_atoms"
import { Label, Title } from "components/atoms/typography"
import { ShortHistoryType } from "services/types"
import { formatDate } from "utils/date/date"
import Link from "components/atoms/link"
import { IconButton } from "components/atoms/button"

const HistoryBlock = ({ data }: { data: ShortHistoryType }) => {
  return (
    <>
      <Wrapper>
        <Content>
          <Row alignItems="center">
            <Label margin="0">{data?.title}</Label>
          </Row>
          <Row alignItems="center" height="100%">
            <Label margin="0">{formatDate(data.dtCreated, "dd/MM/yyyy HH:MM")}</Label>
          </Row>
          <Link styledType="secondary" to={data.id} icon="eye" type="secondary">
            Szczegóły
          </Link>
        </Content>
      </Wrapper>
    </>
  )
}

export default HistoryBlock

const Content = styled(Box)`
  padding: ${({ theme }) => theme.space.XXS};
  padding-left: ${({ theme }) => theme.space.S};
  display: grid;
  grid-gap: ${({ theme }) => theme.space.XS};
  grid-template-columns: 4fr 1fr max-content;
  align-items: center;
`

const Wrapper = styled(Box)<{
  suggested?: boolean
}>`
  background: white;
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: 1px solid ${({ theme }) => theme.color.border};
  overflow: hidden;
`
