import React from "react"
import { useParams, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import DocumentContent from "components/shared/Documents/components/DocumentContent"

const DocumentView = () => {
  const { id = "" } = useParams()
  const [searchParams] = useSearchParams()
  const q = searchParams.get("q") || ""

  return <DocumentContent id={id} q={q} />
}

export default DocumentView
