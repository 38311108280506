import React from "react"
import styled from "styled-components"
import { Outlet, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import PanelContentLayout from "components/layout/PanelContentLayout"
import { RootState } from "store/store"
import Link from "components/atoms/link"

const Profile = () => {
  const navigate = useNavigate()
  const { isVerifying, isAuthenticated, user } = useSelector((state: RootState) => state.auth)
  const { profile } = useSelector((state: RootState) => state.profile)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  return (
    <PanelContentLayout icon="settings" title="Ustawienia" text="Edytuj ustawienia swojego profilu">
      <HeaderWrapper>
        <Link type="secondary" to="/panel/settings/account" justifyContent="flex-start">
          Twoje konto
        </Link>
        {user?.role === "ROLE_PROFILE_ADMIN" && (
          <>
            <Link type="secondary" to="/panel/settings/profile" justifyContent="flex-start">
              Twój profil
            </Link>
            <Link type="secondary" to="/panel/settings/users" justifyContent="flex-start">
              Użytkownicy
            </Link>
            {/*<Link*/}
            {/*  type="secondary"*/}
            {/*  background="white"*/}
            {/*  to="/panel/settings/billing"*/}
            {/*  justifyContent="flex-start"*/}
            {/*  marginBottom="XXS"*/}
            {/*  disabled={true}*/}
            {/*>*/}
            {/*  Płatności*/}
            {/*</Link>*/}
          </>
        )}
        <Link type="secondary" to="/panel/settings/password" justifyContent="flex-start">
          Zmiana hasła
        </Link>
      </HeaderWrapper>
      <Outlet />
    </PanelContentLayout>
  )
}

export default Profile

const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.XS};
`
