import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { Box, Row } from "components/atoms/_atoms"
import { IconButton } from "components/atoms/button"
import { Title } from "components/atoms/typography"
import Tag from "components/atoms/tag"
import { formatDate } from "utils/date/date"
import { t } from "utils/translator"
import { DocumentSignatureType } from "services/types"

const SignatureContentHeader = ({ data }: { data: DocumentSignatureType }) => {
  return (
    <TitleContent>
      <Box>
        <Box alignItems="center">
          <Title margin="0" marginBottom="S">
            {data?.name}
          </Title>
          <TagsWrapper>
            {data?.date && <Tag icon="clock" iconColor="primary" label={formatDate(data?.date)} />}
            <Tag icon="decision" iconColor="primary" label={t(data?.judgmentType)} />
            <Tag icon="court" iconColor="primary" label={t(data?.courtType)} />
          </TagsWrapper>
        </Box>
      </Box>
    </TitleContent>
  )
}

export default SignatureContentHeader

const TagsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const TitleContent = styled(Box)`
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
