import React from "react"
import styled from "styled-components"
import { Box, Row } from "components/atoms/_atoms"
import { useCustomQuery } from "hooks/useCustomQuery"
import { DocumentSignatureType } from "services/types"
import { DocumentSignatureService } from "services/DocumentSignatureService"
import Spinner from "components/atoms/spinner"
import Signature from "components/shared/Documents/Signature/Signature"

const DocumentContent = ({ id, q }: { id: string; q: string }) => {
  const { isLoading, data } = useCustomQuery<DocumentSignatureType>({ key: ["document", id] }, () =>
    DocumentSignatureService.getById(id),
  )

  if (isLoading || !data) {
    return (
      <Row justifyContent="center" width="100%" height="100%">
        <Spinner />
      </Row>
    )
  }

  return <Signature data={data} q={q} />
}

export default DocumentContent
