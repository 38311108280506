import React, { useState } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { Box, Row } from "components/atoms/_atoms"
import { Title } from "components/atoms/typography"
import Button from "components/atoms/button/Button"
import Link from "components/atoms/link"
import Tag from "components/atoms/tag"
import { formatDate } from "utils/date/date"
import { t } from "utils/translator"
import { DocumentSignatureService } from "services/DocumentSignatureService"
import { ShortDocumentSignatureType } from "services/types"
import { IconBox } from "components/atoms/icon"
import { IconButton } from "components/atoms/button"
import { deleteDocumentToFolder, useIsDocumentFavourite } from "store/folders"
import { SentryService, UserFolderService } from "services"

const SignatureRowBlock = ({
  data,
  folderId,
}: {
  data: ShortDocumentSignatureType
  folderId?: string
}) => {
  const isFavourite = useIsDocumentFavourite(data.id)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    if (folderId) {
      try {
        setLoading(true)
        await UserFolderService.deleteToFolder(folderId, data.id)
        dispatch(
          deleteDocumentToFolder({
            documentId: data.id,
            folderId: folderId,
          }),
        )
      } catch (e) {
        SentryService.error("[ERROR UserFolderService.deleteDocumentToFolder]:", e)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <Wrapper>
        <Content>
          <Box>
            <Row alignItems="center">
              <IconBox icon="cv" marginRight="S" />
              <Title margin="0">{data?.name}</Title>
            </Row>
            <TagsWrapper marginTop="XS">
              {data?.date && (
                <Tag
                  icon="clock"
                  iconColor="primary"
                  label={formatDate(data?.date)}
                  background={"background"}
                />
              )}
              <Tag
                icon="decision"
                iconColor="primary"
                label={t(data?.judgmentType)}
                background={"background"}
              />
              <Tag
                icon="court"
                iconColor="primary"
                label={t(data?.courtType)}
                background={"background"}
              />
            </TagsWrapper>
          </Box>
          <ButtonsWrapper justifyContent="flex-end">
            {folderId && (
              <IconButton
                color="primary"
                borderRadius="borderRadius"
                size="45px"
                icon="heart-fill"
                loading={loading}
                onClick={handleDelete}
              />
            )}
            <a
              href={DocumentSignatureService.generateFileUrl(data.id)}
              target="_blank"
              download
              rel="noreferrer"
            >
              <Button icon="save" type="secondary">
                Pobierz
              </Button>
            </a>
            <Link
              styledType="primary"
              to={`/panel/documents/${data.id}`}
              target="_blank"
              icon="eye"
              type="secondary"
            >
              Zobacz dokument
            </Link>
          </ButtonsWrapper>
        </Content>
      </Wrapper>
    </>
  )
}

export default SignatureRowBlock

const ButtonsWrapper = styled(Box)`
  display: flex;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const TagsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const Content = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Wrapper = styled(Box)<{
  suggested?: boolean
}>`
  background: white;
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: 1px solid ${({ theme }) => theme.color.border};
  overflow: hidden;
`
