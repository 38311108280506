import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Box } from "components/atoms/_atoms"
import { Label, LabelH2 } from "components/atoms/typography"
import Spinner from "components/atoms/spinner"
import { updateShowMenu } from "store/ui"
import { AIService } from "services/AIService"
import { setSearchData } from "store/search"
import { UserHistoryService, SentryService } from "services"
import Alert from "components/molecules/alert"

const FunFactBlock = () => {
  const [data, setData] = useState<null | string>(null)

  const getFunFact = async () => {
    try {
      const funFact = await AIService.funFact()
      setData(funFact)
    } catch (e) {
      SentryService.error("[ERROR AIService.funFact]:", e)
      console.log(e)
    } finally {
      // setTimeout(getFunFact, 15000)
    }
  }

  useEffect(() => {
    // getFunFact()
  }, [])

  return (
    <Wrapper>
      <Box marginBottom="S">
        <Spinner />
      </Box>
      {data && (
        <>
          <Label color="primary" marginBottom="XS">
            Czy wiesz że ...
          </Label>
          <LabelH2 textAlign="center">{data}</LabelH2>
        </>
      )}
      <Alert type="warning" marginTop="L">
        Aktualnie wyszukiwanie jest w wersji <b>beta</b>. Czas oczekiwania może być wydłużony ze
        względu na ograniczenia infrastruktury.
      </Alert>
    </Wrapper>
  )
}

export default FunFactBlock

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.L} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 750px;
  margin: 0 auto;
`
