import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Document, Page, pdfjs } from "react-pdf"
import { Box } from "components/atoms/_atoms"
import { DocumentSignatureType } from "services/types"
import { useCustomQuery } from "hooks/useCustomQuery"
import { LocalStorageService } from "services"
import Spinner from "components/atoms/spinner"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.8.69/build/pdf.worker.min.mjs`

const SignatureContentInnerContent = ({ data }: { data: DocumentSignatureType }) => {
  const { isLoading, data: file } = useCustomQuery<string>(
    { key: ["document-file", data?.id] },
    async () => {
      const token = await LocalStorageService.getObject("token")

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/documents/signatures/${data?.id}/file`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        },
      )

      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`)
      }

      const blob = await response.blob()
      return window.URL.createObjectURL(blob)
    },
  )

  const [numPages, setNumPages] = useState<number | null>(null)

  useEffect(() => {
    return () => {
      if (file) {
        window.URL.revokeObjectURL(file)
      }
    }
  }, [])

  if (isLoading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Document
        file={file}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        onLoadError={e => console.log(e)}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <div key={index}>
            <Page
              key={index}
              pageNumber={index + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </div>
        ))}
      </Document>
      {/*<SignatureContentInnerContentText id={data.id} text={data?.content} />*/}
    </Wrapper>
  )
}

export default SignatureContentInnerContent

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.color.backgroundDarker};
  display: flex;
  //justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.space.M};
  overflow: scroll;

  .react-pdf__Page {
    margin-bottom: ${({ theme }) => theme.space.M};
  }
`
