import React from "react"
import "./App.css"
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { RootState } from "store/store"
import PanelLayout from "components/layout/PanelLayout"
import Profile from "pages/Panel/Profile"
import Search from "pages/Panel/Search"
import Login from "pages/Login/Login"
import Spinner from "components/atoms/spinner"
import Favourites from "pages/Panel/Favourites"
import ProfileView from "views/Panel/Settings/ProfileView"
import UserView from "views/Panel/Settings/UserView"
import PasswordView from "views/Panel/Settings/PasswordView"
import UsersView from "views/Panel/Settings/UsersView"
import ActivateAccount from "pages/ActivateAccount/ActivateAccount"
import FavouritesView from "views/Panel/Favourites/FavouritesView"
import FolderFavouritesView from "views/Panel/Favourites/FolderFavouritesView"
import HistoryView from "views/Panel/History/HistoryView"
import History from "pages/Panel/History"
import HistoryInfoView from "views/Panel/History/HistoryInfoView"
import Chat from "pages/Panel/Chat"
import ChatView from "views/Panel/Chat/Chat"
import Document from "pages/Panel/Document"
import DocumentView from "views/Panel/Document/DocumentView"

function Home() {
  const { isVerifying, isAuthenticated, user } = useSelector((state: RootState) => state.auth)
  const { profile } = useSelector((state: RootState) => state.profile)

  if (isVerifying) {
    return (
      <InitWrapper>
        <Spinner />
      </InitWrapper>
    )
  }

  return (
    <InitWrapper>
      <BrowserRouter>
        <Routes>
          {!isAuthenticated && !user && (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
          {isAuthenticated && user && user.status === "complete_user" && (
            <>
              <Route path="/activate-account" element={<ActivateAccount />} />
              <Route path="*" element={<Navigate to="/activate-account" />} />
            </>
          )}
          {isAuthenticated &&
            user &&
            user.status === "active" &&
            profile &&
            profile.status === "active" && (
              <>
                <Route path="panel" element={<PanelLayout />}>
                  {/*<Route path="signatures/:id" element={<DocumentSignature url={} />} />*/}
                  <Route path="documents" element={<Document />}>
                    <Route path=":id" element={<DocumentView />} />
                  </Route>
                  <Route path="search" element={<Search />} />
                  <Route path="chat" element={<Chat />}>
                    <Route path=":chatId" element={<ChatView />} />
                  </Route>
                  <Route path="history" element={<History />}>
                    <Route path="" element={<HistoryView />} />
                    <Route path=":historyId" element={<HistoryInfoView />} />
                  </Route>
                  <Route path="favourites" element={<Favourites />}>
                    <Route path="" element={<FavouritesView />} />
                    <Route path="folders/:folderId" element={<FolderFavouritesView />} />
                  </Route>
                  <Route path="settings" element={<Profile />}>
                    <Route path="account" element={<UserView />} />
                    <Route path="password" element={<PasswordView />} />
                    {user.role === "ROLE_PROFILE_ADMIN" && (
                      <>
                        <Route path="profile" element={<ProfileView />} />
                        <Route path="users" element={<UsersView />} />
                      </>
                    )}
                    <Route path="" element={<Navigate to="account" />} />
                  </Route>
                </Route>
                <Route path="*" element={<Navigate to="/panel/search" />} />
              </>
            )}
        </Routes>
      </BrowserRouter>
    </InitWrapper>
  )
}

export default Home

const InitWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
`
