import React from "react"
import styled from "styled-components"
import { Outlet } from "react-router-dom"
import PanelNavigationLayout from "./PanelNavigationLayout"

const PanelLayout = ({ children }: any) => {
  return (
    <Wrapper>
      <PanelNavigationLayout />
      <Content>
        <InnerContent className="hide_scroll">
          <Outlet />
        </InnerContent>
      </Content>
    </Wrapper>
  )
}

export default PanelLayout

const InnerContent = styled.div`
  height: 100%;
  width: 100%;
  overflow: scroll;
  background: white;
  border-radius: ${({ theme }) => theme.variable.borderRadiusXLarge};
  border: 1px solid ${({ theme }) => theme.color.borderLight};
`

const Content = styled.div`
  flex: 1 1;
  height: 100vh;
  padding: ${({ theme }) => theme.space.XS};
  padding-left: 0;
`

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.color.background};
  display: flex;
`
