import React, { useState } from "react"
import styled from "styled-components"
import { ChatRow } from "components/shared/Documents/components/DocumentChat"
import { DocumentSignatureType } from "services/types"
import { Box } from "components/atoms/_atoms"
import SignatureContentInfoPrimary from "components/shared/Documents/Signature/components/SignatureContentInfoPrimary"
import SignatureContentHeader from "components/shared/Documents/Signature/components/SignatureContentHeader"
import SignatureContentFooter from "components/shared/Documents/Signature/components/SignatureContentFooter"
import { useCustomQuery } from "hooks/useCustomQuery"
import { AIService } from "services/AIService"
import QucikExplanationBlock from "components/shared/blocks/QucikExplanationBlock"

const SignatureContentInfo = ({
  q,
  data,
  showChat = true,
  setShowChat,
}: {
  q: string
  data: DocumentSignatureType
  showChat: boolean
  setShowChat: (e: boolean) => void
}) => {
  const isValidQ = !!q && q !== ""
  const { isLoading: isLoadingExplanation, data: explanation } = useCustomQuery<string>(
    { key: ["explanation", data.id, q] },
    () => AIService.quickAnswer({ id: data.id, message: q }),
    isValidQ,
  )

  const [accordion, setAccordion] = useState<null | string>("basic")

  const handleShowAccordion = (value: boolean, name: string) => {
    if (!value) {
      setAccordion(null)
    } else {
      setAccordion(name)
    }
  }

  return (
    <Wrapper className="hide_scroll">
      <WrapperHeader>
        <SignatureContentHeader data={data} />
      </WrapperHeader>
      <WrapperContent className="hide_scroll">
        {isValidQ && (
          <QucikExplanationBlock isLoading={isLoadingExplanation}>
            {explanation}
          </QucikExplanationBlock>
        )}
        {!showChat && <ChatRow setShow={setShowChat} />}
        <SignatureContentInfoPrimary
          data={data}
          show={accordion === "basic"}
          setShow={e => handleShowAccordion(e, "basic")}
        />
      </WrapperContent>
      <WrapperFooter>
        <SignatureContentFooter id={data?.id} />
      </WrapperFooter>
    </Wrapper>
  )
}

export default SignatureContentInfo

const WrapperHeader = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  border-bottom: 1px solid ${({ theme }) => theme.color.borderLight};
  position: sticky;
  top: 0;
  background: white;
`

const WrapperContent = styled(Box)`
  display: flex;
  flex: 1 1;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.S};
  overflow: scroll;
  overflow-x: hidden;
  padding: ${({ theme }) => theme.space.S};
`

const WrapperFooter = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.color.borderLight};
  padding: ${({ theme }) => theme.space.S};
  position: sticky;
  bottom: 0;
  background: white;
`

const Wrapper = styled(Box)`
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: calc(100svh - 2px - (${({ theme }) => theme.space.XS} * 2));
`
