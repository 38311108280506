import React, { ReactNode } from "react"
import styled from "styled-components"
import { Box, Row } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles"
import { H4 } from "components/atoms/typography"
import { IconButton } from "components/atoms/button"
import { IconBox } from "components/atoms/icon"

const Accordion = ({
  disabled = false,
  icon,
  title,
  children,
  show,
  setShow = () => {},
  ...rest
}: BaseStyles & {
  disabled?: boolean
  icon?: string
  title: string
  show: boolean
  setShow?: (e: boolean) => void
  children?: ReactNode
}) => {
  return (
    <Wrapper {...rest}>
      <Header>
        <Row alignItems="center" opacity={disabled ? 0.6 : 1}>
          {icon && <IconBox small icon={icon} marginRight="S" />}
          <H4 margin="0">{title}</H4>
        </Row>
        <IconButton icon={show ? "arrow-top" : "arrow-bottom"} onClick={() => setShow(!show)} />
      </Header>
      {show && <Content>{children}</Content>}
    </Wrapper>
  )
}

export default Accordion

const Content = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  border-top: 1px solid ${({ theme }) => theme.color.borderLight};
`

const Header = styled(Box)`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.XS} ${({ theme }) => theme.space.S};
`

const Wrapper = styled(Box)`
  border: 1px solid ${({ theme }) => theme.color.borderLight};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  //background: ${({ theme }) => theme.color.background};
`
