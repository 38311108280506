import React from "react"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { Label, Title } from "components/atoms/typography"
import { UserFolderType } from "services/types"
import Icon from "components/atoms/icon"
import { Box } from "components/atoms/_atoms"

const FavouritesFolderBlock = ({ primary, data }: { primary?: boolean; data: UserFolderType }) => {
  return (
    <>
      <Wrapper to={`folders/${data.id}`}>
        <Content>
          <Icon
            icon="folder"
            size="XL"
            color={primary ? "primary" : "secondary"}
            marginBottom="XS"
          />
          <Title color={primary ? "primary" : "secondary"} margin="0" marginBottom="XXS">
            {data?.name}
          </Title>
          <Label type="third" color="third">
            Liczba dokumentów: {data.count}
          </Label>
        </Content>
      </Wrapper>
    </>
  )
}

export default FavouritesFolderBlock

const Content = styled(Box)`
  display: block;
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  padding: ${({ theme }) => theme.space.S};
  overflow: hidden;
`

const Wrapper = styled(NavLink)`
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  height: 100%;
`
