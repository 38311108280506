import React from "react"
import SCStyled, { css } from "styled-components"
import FormControl from "@mui/material/FormControl"
import { Select as MUSelect } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import { styled } from "@mui/material/styles"
import ListSubheader from "@mui/material/ListSubheader"
import { Absolute, Box } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles"
import { baseTheme } from "theme"
import { Label } from "components/atoms/typography"
import Spinner from "components/atoms/spinner"
import Icon from "components/atoms/icon"

const StyledFormControl = styled(FormControl)(() => ({
  "width": "100%",
  "heigth": "max-content",
  "display": "block",
  "background": "none",
  "overflow": "hidden",

  ".MuiInputBase-root ": {
    width: "100%",
  },
}))

const StyledSelect = styled(MUSelect)(() => {
  return {
    "borderRadius": baseTheme.variable.borderRadius,
    "padding": "0",

    ".MuiSelect-select": {
      "display": "flex",
      "alignItems": "center",
      "color": baseTheme.color.second,
      "minHeight": "45px !important",
      "fontSize": baseTheme.font.L,
      "lineHeight": "23px",
      "fontWeight": baseTheme.font.light,
      "padding-left": baseTheme.font.XS,
      "padding-top": baseTheme.font.XS,
      "padding-bottom": baseTheme.font.XS,
    },
  }
})

export type SelectProps = BaseStyles & {
  label?: string
  name: string
  value?: unknown
  placeholder?: string
  options?: any[] | any[][]
  onChange?: (e: { name: string; value: string }) => void
  disabled?: boolean
  loading?: boolean
  icon?: string
  error?: {
    type?: string
    message?: string
  }
  rhf?: object & { ref?: object }
}

const Select = ({
  options = [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  value,
  placeholder,
  label,
  name,
  disabled,
  error,
  loading,
  icon,
  rhf = {},
  ...rest
}: SelectProps) => {
  const { ref, ...rhfRest } = rhf
  const finalOptions = options.map(el => (Array.isArray(el) ? el : [el, el]))

  const handleOnChange = (e: any) => {
    onChange({
      name,
      value: e.target.value,
    })
  }

  return (
    <Box {...rest}>
      {label && (
        <Label type="secondary" marginBottom="XS">
          {label}
        </Label>
      )}
      <StyledBox zIndex={0} disabled={disabled} hasIcon={!!icon} {...rest}>
        <StyledFormControl>
          <StyledSelect
            defaultValue={value}
            value={value}
            disabled={disabled || finalOptions.length === 0}
            error={!!error}
            variant="outlined"
            helperText={error?.type === "required" ? "To pole jest wymagane" : error?.message}
            onChange={handleOnChange}
            placeholder={placeholder}
            {...rhfRest}
            // @ts-ignore
            inputRef={ref}
          >
            {/*<MenuItem value="">*/}
            {/*  <em>-</em>*/}
            {/*</MenuItem>*/}
            {finalOptions.map(option => {
              const value = Array.isArray(option) ? option[1] : option
              const label = Array.isArray(option) ? option[0] : option
              if (value === "#") return <ListSubheader>{label}</ListSubheader>

              return (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              )
            })}
          </StyledSelect>
        </StyledFormControl>
        {loading && (
          <SpinnerWrapper>
            <Spinner size="15px" borderWidth={3} />
          </SpinnerWrapper>
        )}
        {icon && (
          <Absolute
            left={baseTheme.space.S}
            top="50%"
            style={{ transform: "translateY(-50%)" }}
            display="flex"
            alignItems="center"
          >
            <Icon size="15px" icon={icon} />
          </Absolute>
        )}
      </StyledBox>
    </Box>
  )
}

export default Select

const SpinnerWrapper = SCStyled.div`
    position: absolute;
    right: ${({ theme }) => theme.space.XS};
    top: 50%;
    transform: translateY(-50%);
`

const StyledBox = SCStyled(Box)<
  BaseStyles & {
    disabled?: boolean
    hasIcon?: boolean
  }
>`
  margin: 0;
  
    *{
      box-sizing: border-box;
          font-family: 'Manrope' !important;
    }
    
    .MuiOutlinedInput-notchedOutline {
      border: none;
    };
    
      .MuiInputBase-root {
        border: 1px solid ${({ theme, borderColor = "border" }) => theme.color[borderColor]};
        border-radius: ${({ theme, borderRadius = "borderRadius" }) =>
          theme.variable[borderRadius] ?? borderRadius} !important;
      };
      
      .MuiInputBase-input {
              color: ${({ theme, color = "second" }) => theme.color[color] ?? color} !important;
      }
      
     .Mui-error {
        color: #f8563f !important;
      };
      
      ${({ hasIcon }) =>
        hasIcon &&
        css`
          .MuiSelect-select {
            padding-left: 39px !important;
          }
        `};    
        
      ${({ disabled }) =>
        disabled &&
        css`
          cursor: not-allowed;

          .MuiSvgIcon-root {
            display: none;
          }
        `};
    `
